import React from 'react'
import { FaTwitter, FaLinkedin, FaInstagram, FaChevronRight } from 'react-icons/fa'


import Layout from '../components/layout'
import SEO from '../components/seo'
import BreadCrumb from '../components/breadCrumb'

const crumbs = [
  {
    depth: '1',
    url: '/',
    title: 'Home',
    styles: '',
  },
]

function ContactPage() {
  return (
    <Layout>
      <SEO
        keywords={[`UX`, `Freelance`, `Designer`, `Researcher`]}
        title="Contact"
      />
      <BreadCrumb crumbs={crumbs} />
      <section className="flex flex-col md:flex-row items-center">
        <section id="contact" className="md:w-2/3 md:mr-8 mb-3">
          <h3>
            If you would like to work together, talk about design or ask a question, please get in touch.
          </h3>
          <h3 className="leading-loose font-bold">Connect with me:</h3>
          <div className="flex flex-row items-center mb-8">
            <a
              className="pr-8 hover:text-gray-600"
              href="//twitter.com/emmishone"
              target="_blank"
              rel="nofollow"
            >
              <FaTwitter size="2em" />
            </a>
            <a
              className="pr-8 hover:text-gray-600"
              href="//instagram.com/emshone"
              target="_blank"
              rel="nofollow"
            >
              <FaInstagram size="2em" />
            </a>
            <a
              className="pr-8 hover:text-gray-600"
              href="//linkedin.com/in/emily-shone-a3264b56"
              target="_blank"
              rel="nofollow"
            >
              <FaLinkedin size="2em" />
            </a>
          </div>
        </section>
      </section>
      <section>
        <form name="contact" method="post" action="/success" data-netlify="true" data-netlify-honeypot="bot-field" className="md:w-1/2">
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <h3 className="mb-5 font-bold">Drop me a note:</h3>

          <label className="block font-bold mb-2 text-sm" htmlFor="first-name">
            Your name
          </label>

          <input
            className="appearance-none block border-gray-400 border-2 mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
            id="first-name"
            placeholder="Name"
            type="text"
            name="Name"
            required
          />

          <label className="block font-bold mb-2 text-sm" htmlFor="last-name">
            Email address
          </label>

          <input
            className="appearance-none block border-gray-400 border-2 mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
            id="email-address"
            placeholder="email@placeholder.co.uk"
            type="text"
            name="Email"
            required
          />

          <label className="block font-bold mb-2 text-sm" htmlFor="message">
            Message
          </label>

          <textarea
            className="appearance-none border-gray-400 border-2 mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
            id="message"
            placeholder="Say something..."
            rows="8"
            Name="Message"
            required
          />

          <button type="submit" className="hover:bg-teal-600 bg-gray-900 text-white font-bold py-2 px-4 rounded-full">
            Send me your message <FaChevronRight className="inline" />
          </button>
        </form>
      </section>
    </Layout>
  )
}

export default ContactPage
